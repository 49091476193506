/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Text, Button, SeparateLine, SeparateLineWrap, Fullmap, FullmapWrap } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Poděkování"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--50 pt--80" name={"uvod"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image style={{"maxWidth":670}} src={"https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=860x_.png"} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=2000x_.png 2000w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--185 w--300 lh--1" content={""}>
                    </Title>

                    <Subtitle className="subtitle-box fs--30 ls--004 lh--1 mt--0" content={"15 – 6 – 2024"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"podekovani"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"Naši milí,\njsme moc rádi, že jste s námi strávili náš velký den.<br>Nyní si můžete u nás v galerii prohlédnout videa a fotografie ze svatby."}>
              </Text>

              <Button className="btn-box" content={"GALERIE"} target={null} use={"page"} href={"/cs/galerie"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"marginTop":102}} name={"nx7w9hk1dfg"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginTop":0,"paddingLeft":0,"paddingRight":0,"paddingBottom":0,"backgroundColor":"rgba(205,198,182,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Fullmap className="--style2" name={"bzidc11xwyg"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"galerie"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/6dc8d875aae34f6d8b8867d08a4ed2b1_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/6dc8d875aae34f6d8b8867d08a4ed2b1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/6dc8d875aae34f6d8b8867d08a4ed2b1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/6dc8d875aae34f6d8b8867d08a4ed2b1_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/ebc9bca0d8e347679432174c16ce6da2_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/ebc9bca0d8e347679432174c16ce6da2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/ebc9bca0d8e347679432174c16ce6da2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/ebc9bca0d8e347679432174c16ce6da2_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"3571u7dcfwz"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--80" style={{"paddingTop":0}} name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"Děkujeme!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/galerie\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}